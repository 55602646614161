.tab-buttons{
    width: 100%;
    height: 30px;
    border-style: none;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #333;
    color: white;
    border-style: solid 1px #333;
}

.tab-buttons:hover{
    color: #333;
    background-color: white;
}

.tab-name-container{
    width: 100%;
    display: flex;
    align-items: stretch;
    margin-bottom: 50px;
}