.styling {
    display: 'flex';
    flex-direction: 'row';
    height: '50px';
    background-color: '#fff';
    background-image: url('../../Resources/Images/pikachuRepeating.png');
}

.lockedStyle{
    display: 'flex';
    flex-direction: 'row';
    height: '50px';
    position: "fixed";
    top: 0;
    width: "100%";
    background-color: '#fff';
    background-image: url('../../Resources/Images/pikachuRepeating.png');
}